import { Select } from "@kobalte/core";
import type { RouteSectionProps } from "@solidjs/router";
import { action, useAction, useSubmission } from "@solidjs/router";
import type { Remote } from "comlink";
import { batch, createEffect, createSignal, on, onMount, Show } from "solid-js";
import type { JsMonomer } from "~/assets/wasm/single/tbnenergy_wasm";
import ChangedIndicator from "~/components/ChangedIndicator";
import { useDataStore } from "~/components/DataStore";
import { Editor } from "~/components/editor/Editor";
import { HelpTooltip } from "~/components/HelpTooltip";
import { Histogram } from "~/components/Histogram";
import { Output } from "~/components/Output";
import { Steps } from "~/components/Steps";
import { TextInput } from "~/components/TextField";
import { calculateConcentrations as _calculateConcentrations } from "~/server/hooks/nupack";
import type { ComputeFormData } from "~/types/formData";
import { concentrationMultiple } from "~/util/concentration";
import { setMonomers } from "~/util/monomer";
import { Polymer } from "~/util/polymer";
import { showToast } from "~/util/toaster";

import CheckIcon from "~icons/heroicons/check-20-solid";
import CaretSortIcon from "~icons/heroicons/chevron-up-down-20-solid";

const calculateConcentrationsAction = action(async (args: Parameters<typeof _calculateConcentrations>) => {
  "use server";
  return await _calculateConcentrations(...args);
}, "calculateConcentrations");

export default function Compute(props: RouteSectionProps) {
  const dataStore = useDataStore();

  let workerInstance: Remote<typeof import("~/worker/web")>;
  onMount(() => {
    workerInstance = new ComlinkWorker<typeof import("~/worker/web")>(new URL("../worker/web", import.meta.url));
  });

  let monomer_concentrations: number[] = [];

  const computeEnergiesAction = action(async (formData: FormData) => {
    const data = Object.fromEntries(formData) as unknown as ComputeFormData;
    // infinity handling
    data.max_complexes ||= "-1";

    // parse monomer inputs
    const monomer_inputs = data.monomer_inputs
      .trim()
      .split("\n")
      .map((line) => line.replace(/#.*$/, ""))
      .filter((line) => line.length > 0);
    monomer_concentrations = [];

    const monomers = monomer_inputs.map((input) => {
      const [rest, concentration] = input.split(/\s*,\s*/);
      const [name, _sites] = rest.split(/\s*:\s*/);
      const sites = _sites ?? name;
      if (!concentration) throw new Error(`Concentration not specified on monomer '${name ?? _sites}'.`);
      if (isNaN(+concentration)) throw new Error(`Invalid concentration on monomer '${name ?? _sites}'.`);
      const monomer: JsMonomer = { name, sites: sites.split(/\s+/g) };

      monomer_concentrations.push(+concentration * concentrationMultiple(data.concentrationUnit));

      return monomer;
    });

    const binding_energy_dict: Map<string, number> = new Map();
    const energies_inputs = data.energies_inputs
      .trim()
      .split("\n")
      .map((line) => line.replace(/#.*$/, ""));
    for (const input of energies_inputs) {
      if (input.trim().length == 0) continue;
      const [name, energy] = input.replace(/#.*$/, "").split(/\s*=\s*/);
      binding_energy_dict.set(name, +energy);
    }

    if (import.meta.env.DEV) console.time("computing");

    const computeOutput = await workerInstance.computeEnergies({
      max_complexes: +data.max_complexes == -1 ? 100000 : +data.max_complexes,
      max_complex_energy: +data.max_complex_energy,
      temperature: +(data.temperature ?? 0),
      monomers: monomers,
      default_binding_energy: +data.binding_energy,
      binding_energy_dict,
    });
    if (import.meta.env.DEV) console.timeEnd("computing");

    batch(() => {
      // dataStore.computeFormData.setCurrentStep(2);
      dataStore.computeFormData.setCurrentStep(2);
      dataStore.computeFormData.setActive(data);

      setMonomers(computeOutput.monomers);
      dataStore.updateRawData(computeOutput);

      if (import.meta.env.DEV) console.time("generate polymers");
      const polymers = computeOutput.complexes.map((complex, i) =>
        Polymer.fromComplex(complex, computeOutput.free_energies[i]),
      );
      if (import.meta.env.DEV) console.timeEnd("generate polymers");

      dataStore.updatePolymers(polymers);
    });

    // server/worker compute testing
    /* const serverCompute = server$((data, monomers) => {
          return computeEnergies({
            max_complexes: ~~data.max_complexes,
            max_complex_energy: ~~data.max_complex_energy,
            temperature: ~~(data.temperature ?? 0),
            monomers: monomers,
          });
        });

        const promises = [
          new Promise<ComputeOutput>((resolve) => {
            console.time("Compute Worker");
            const freeEnergyOutput = workerInstance
              .computeEnergies({
                max_complexes: ~~data.max_complexes,
                max_complex_energy: ~~data.max_complex_energy,
                temperature: ~~(data.temperature ?? 0),
                monomers: monomers,
              })
              .then((output) => {
                console.timeEnd("Compute Worker");
                resolve(output);
              });
          }),
          new Promise<ComputeOutput>((resolve) => {
            console.time("Compute Server");
            serverCompute(data, monomers).then((output) => {
              console.timeEnd("Compute Server");
              resolve(output);
            });
          }),
        ];

        return await Promise.race(promises); */
  }, "computeEnergies");

  const computeEnergies = useAction(computeEnergiesAction);
  const outputEnergies = useSubmission(computeEnergiesAction);

  const calculateConcentrations = useAction(calculateConcentrationsAction);
  const outputConcentrations = useSubmission(calculateConcentrationsAction);

  createEffect(() => {
    dataStore.loaders.setLoadingState("computeEnergies", outputEnergies.pending ?? false);
  });
  createEffect(() => {
    dataStore.loaders.setLoadingState("outputConcentrations", outputConcentrations.pending ?? false);
  });

  // error handling
  createEffect(() => {
    if (outputConcentrations.error && !outputConcentrations.pending) {
      showToast({
        title: "Error",
        description: "Concentrations solver timed out.",
      });
    }
  });

  createEffect(() => {
    if (outputEnergies.error && !outputEnergies.pending) {
      showToast({
        title: "Parsing Error",
        description: (outputEnergies.error as Error).message,
      });
    }
  });

  createEffect(
    on(
      () => outputEnergies.pending || outputConcentrations.pending,
      (i) => {
        if (!i)
          resultPaneRef()?.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
      },
    ),
  );

  // TODO: figure out why solid-styled is failing
  // css`
  //   .select__value[data-placeholder-shown] {
  //     color: hsl(240 4% 46%);
  //   }

  //   :global(.dark) .select__value[data-placeholder-shown] {
  //     color: hsl(240 4% 80%);
  //   }

  //   .select__content {
  //     transform-origin: var(--kb-select-content-transform-origin);
  //     animation: appear 0ms;
  //   }

  //   .select__content[data-expanded] {
  //     animation: appear 400ms cubic-bezier(0.19, 1, 0.22, 1);
  //   }

  //   @keyframes appear {
  //     from {
  //       opacity: 0;
  //       transform: translate3d(0, -0.75rem, 0);
  //     }
  //     to {
  //       opacity: 1;
  //       transform: translate3d(0, 0rem, 0);
  //     }
  //   }
  // `;

  let formRef: HTMLFormElement;
  const [resultPaneRef, setResultPaneRef] = createSignal<HTMLDivElement>();

  return (
    <main class="flex flex-auto flex-col items-center p-8 dark:text-white">
      <form
        action={computeEnergiesAction}
        method="post"
        class="flex flex-col md:flex-row"
        onChange={(e) => {
          // setCurrentStep(1);
        }}
        ref={(e) => (formRef = e)}
      >
        <div class="pr-4">
          <div class="flex flex-wrap gap-4">
            <TextInput
              type="number"
              name="max_complexes"
              label="Maximum Complex Size"
              placeholder="∞"
              helpTooltip="Maximum complex size considered. Leave empty (infinite maximum complex size) to consider all complexes that cannot be split without decreasing bonds (see Help)."
              min="1"
              value={
                dataStore.computeFormData.pending.max_complexes == "-1"
                  ? ""
                  : dataStore.computeFormData.pending.max_complexes
              }
              onChange={(value) =>
                dataStore.computeFormData.updatePending("max_complexes", Math.trunc(+value || -1).toString())
              }
            />

            <TextInput
              type="number"
              name="max_complex_energy"
              label="Maximum Complex Energy"
              required
              defaultValue="0"
              helpTooltip="Maximum Complex Energy (kcal/mol). Set to a more negative value to ignore complexes with higher energy."
              oninput={(e) => {
                // only consider negative inputs
                if (+e.target.value > 0) {
                  e.target.value = `-${e.target.value}`;
                }
              }}
              value={dataStore.computeFormData.pending.max_complex_energy}
              onChange={(value) => dataStore.computeFormData.updatePending("max_complex_energy", value)}
            />
            <TextInput
              type="number"
              name="temperature"
              label="Temperature"
              defaultValue="25"
              helpTooltip="Temperature in degrees Celsius."
              value={dataStore.computeFormData.pending.temperature}
              onChange={(value) => dataStore.computeFormData.updatePending("temperature", value)}
            />
            {/* <TextInput type="number" name="default_concentration" label="Default Concentration" defaultValue="10" /> */}
            <Select.Root
              name="concentrationUnit"
              options={["mM", "µM", "nM", "pM"]}
              placeholder="Select concentration unit"
              defaultValue={"nM"}
              disallowEmptySelection
              value={dataStore.computeFormData.pending.concentrationUnit}
              onChange={(value) => dataStore.computeFormData.updatePending("concentrationUnit", value)}
              itemComponent={(props) => (
                <Select.Item
                  item={props.item}
                  class="relative h-8 flex select-none items-center justify-between rounded-lg px-2 text-neutral-900 outline-none ui-highlighted:(bg-primary-500 text-white) dark:text-neutral-100 dark:ui-highlighted:bg-primary-500"
                >
                  <Select.ItemLabel>{props.item.rawValue}</Select.ItemLabel>
                  <Select.ItemIndicator class="select__item-indicator">
                    <CheckIcon class="h-5 w-5" />
                  </Select.ItemIndicator>
                </Select.Item>
              )}
            >
              <Select.HiddenSelect />
              <Select.Label class="mb-2 inline-block text-sm text-sm text-neutral-950 font-medium dark:text-white">
                Concentration Units
              </Select.Label>
              <Select.Trigger
                user:solid-styled
                class="max-w-[5rem] w-full inline-flex items-center justify-between border border-neutral-300 rounded-lg bg-neutral-50 p-2.5 text-sm text-neutral-900 outline-none dark:border-neutral-600 dark:bg-neutral-700 dark:text-white focusable-form"
                aria-label="Concentration Unit"
              >
                <Select.Value<string> class="select__value text-base" use:solid-styled>
                  {(state) => state.selectedOption()}
                </Select.Value>
                <Select.Icon>
                  <CaretSortIcon class="h-4 w-4 text-neutral-600 dark:text-neutral-400" />
                </Select.Icon>
              </Select.Trigger>
              <Select.Portal>
                <Select.Content
                  class="select__content border border-neutral-200 rounded-lg bg-neutral-50 shadow-md dark:border-neutral-600 dark:bg-neutral-700"
                  use:solid-styled
                >
                  <Select.Listbox class="max-h-sm p-1.5 text-base outline-none" />
                </Select.Content>
              </Select.Portal>
            </Select.Root>
          </div>
          <div class="flex flex-col-reverse gap-4 md:flex-row md:items-center">
            <div class="flex-1">
              <label class="mb-2 mt-6 flex-inline items-center gap-1 text-sm text-sm text-neutral-950 font-medium dark:text-white">
                Site Binding Energies <span class="text-zinc-600 italic dark:text-zinc-300">{"(Optional)"}</span>{" "}
                <HelpTooltip tooltipText="Customized free binding energy for the specified binding sites in kcal/mol." />
                <ChangedIndicator key={"energies_inputs"} />
              </label>
              <Editor
                onValueChange={(value) => dataStore.computeFormData.updatePending("energies_inputs", value)}
                value={dataStore.computeFormData.pending.energies_inputs}
                lang="energies"
              />
              <textarea
                class="hidden"
                aria-hidden="true"
                name="energies_inputs"
                value={dataStore.computeFormData.pending.energies_inputs}
              />
            </div>
            <TextInput
              type="number"
              step="any"
              name="binding_energy"
              label="Default Binding Energy"
              helpTooltip={'Default binding energy for the sites not listed in "Free Binding Energies" in kcal/mol.'}
              required
              value={dataStore.computeFormData.pending.binding_energy}
              onChange={(value) => dataStore.computeFormData.updatePending("binding_energy", value)}
            />
          </div>
          <label class="mb-2 mt-6 flex-inline items-center gap-1 text-sm text-sm text-neutral-950 font-medium dark:text-white">
            Monomers and Concentrations <HelpTooltip tooltipText="Monomers and their corresponding concentrations." />
            <ChangedIndicator key={"monomer_inputs"} />
          </label>
          <Editor
            class="min-h-22.7"
            onValueChange={(value) => dataStore.computeFormData.updatePending("monomer_inputs", value)}
            value={dataStore.computeFormData.pending.monomer_inputs}
            lang="monomers"
          />
          <textarea
            class="hidden"
            aria-hidden="true"
            name="monomer_inputs"
            value={dataStore.computeFormData.pending.monomer_inputs}
          />
        </div>
        <div class="flex-shrink border-l-2 border-l-zinc-200 pl-8 transition-border-color duration-100 dark:border-l-zinc-800">
          <Steps.Root
            class="sticky top-24"
            step={dataStore.computeFormData.currentStep}
            loading={dataStore.loaders.isLoading}
          >
            <Steps.Node step={1} class="flex flex-col gap-2">
              <button
                class="min-w-50 bg-primary-500 text-white dark:bg-primary-600 btn"
                onClick={[dataStore.computeFormData.setCurrentStep, 1]}
                disabled={outputEnergies.pending}
                data-umami-event="compute-energies"
              >
                Compute Energies
              </button>
            </Steps.Node>
            <Steps.Node step={2}>
              <button
                class="min-w-50 whitespace-nowrap bg-primary-500 text-white dark:bg-primary-600 btn"
                disabled={outputConcentrations.pending}
                data-umami-event="calculate-concentrations"
                classList={{
                  "opacity-70": dataStore.computeFormData.currentStep < 2,
                }}
                onClick={async (e) => {
                  // stop form submission
                  e.preventDefault();

                  if (dataStore.computeFormData.hasChanges) {
                    dataStore.computeFormData.setCurrentStep(1);

                    await batch(async () => {
                      // run computeEnergies
                      const formData = new FormData(formRef);
                      await computeEnergies(formData);
                      if (outputEnergies.error != undefined) throw new Error();
                    });
                  }
                  if (dataStore.rawData().complexes.length <= 0) return;

                  if (dataStore.polymers().length > 500000) {
                    // show toast
                    showToast({
                      title: "Too many complexes. (>500000)",
                      description:
                        "Too many complexes to calculate concentrations for. Please reduce the number of complexes by making the Maximum Complex Energy threshold more negative, or decrease the Maximum Complex Size.",
                    });
                    return;
                  }

                  const result = await calculateConcentrations([monomer_concentrations, dataStore.rawData()]);
                  if (!result) return;

                  dataStore.updateRawData(result);
                  dataStore.updatePolymers(
                    result.complexes
                      .map((complex, i) => Polymer.fromComplex(complex, result.free_energies[i]))
                      .map((polymer, i) => ((polymer.concentration = result.concentrations[i]), polymer)),
                  );

                  dataStore.computeFormData.setCurrentStep(4);
                }}
              >
                Calculate Concentrations
              </button>
            </Steps.Node>
            <Steps.Node
              step={3}
              class="font-medium"
              classList={{
                "opacity-50 group-hover:opacity-60": dataStore.computeFormData.currentStep < 3,
              }}
            >
              <button
                class="bg-zinc-200/50 dark:bg-zinc-700/30 btn"
                onClick={(e) => {
                  e.preventDefault();

                  resultPaneRef()?.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                    inline: "nearest",
                  });
                }}
                disabled={dataStore.computeFormData.currentStep < 3}
              >
                View output
              </button>
            </Steps.Node>
          </Steps.Root>
          <Show when={import.meta.env.DEV && ((outputEnergies.error ?? outputConcentrations.error) as Error)}>
            {(error) => {
              console.error(error());
              return <p class="mt-4 text-red-500">Error: {error().message}</p>;
            }}
          </Show>
        </div>
      </form>
      <Show when={dataStore.computeFormData.currentStep > 1 && dataStore.polymers()}>
        <div ref={setResultPaneRef} class="mt-8 w-full scroll-mt-80vh container">
          <Histogram
            class="contain-paint mb-4 box-content min-h-96 border-2 border-zinc-200 rounded-lg dark:border-zinc-700"
            values={dataStore.rawData().free_energies}
          ></Histogram>
          <Output polymers={dataStore.polymers()}></Output>
        </div>
      </Show>
    </main>
  );
}
